
.heading-instaclone{
  color: rgba(51, 51, 51, 0.732);
  font-size: 30px;
  display: flex;
  justify-content: space-between; 
  background-color: rgb(150, 186, 186);
  padding-left: 3.31%;
}
.app-name-insta{
  padding-top: 10px;
  padding-bottom: 10px;

}
.cam-icon{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 7.1%;
  text-align: end;

}
.cam-img{
  width: 1em;
}
.three-dots{
  width: 5%;
 margin:auto;

}
.edit-delete{
  list-style-type: none;
}
.edit-delete>li{
  font-size:1em;
  margin: 0;
  width: 3em;
  display: none;
}
.three-dots:hover .edit-delete>li{
  display: block;
  cursor: pointer;
} 
.edit-delete>li:hover{
  font-weight: 500;
  cursor: pointer;

}
.full-body{
 width: 50%;
 margin: auto;
}

.postview{
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 243, 243);
  margin: 10px;
  padding: 10px;
  border: 1px ;
  width: 80%;
}

.user{
  display: flex;
  justify-content: space-between;

}
.username{
  margin: 0;
}

.post>div>img{
  width: 100%;
  margin: 0;
  height:30em;
}

.like-share{
  display: flex; 
  margin: 10px; 
}
.like-share>*{
  padding-right: 10px;
}
.like-share>*:last-child{
  margin-left: calc(100% - 210px);
}
.likes,.description{
  margin: 0px 10px;
}
