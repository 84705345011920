.registration{
    /* background-color: rgba(193,189,186,255); */
    width: 100%;
    height: 100vh;
    border: 1px solid;
    /* background-color: rgba(26,176,136,255); */
}
.register-input-fields{
    border: none;
    border-bottom: 1px solid red;
    height: 2em;
    width: 20em;
    color: rgb(238, 230, 230);
    background-color: rgba(35,50,61,255);
}

label,h1{
    color: rgb(238, 230, 230);
}
h1{
    font-weight: 400;
    margin: auto;
    text-align: center;

}

.registraion-form-container{
    width:40em;
    height: 30em;
    margin: 10em auto;
    padding: 1em;
    text-align: end;
    background-color: rgba(35,50,61,255);
    border-radius: 0.5em;
}
.register-form{
    width:30em;
    margin: auto;
}
.register-form,label,.register-form>*{
    background-color: rgba(35,50,61,255);
}
button{
    background-color: rgba(26,176,136,255);
    border: none;
    width: 15em;
    height: 3em;
    text-align: center;
 }
 button:hover{
    background-color: rgb(125, 75, 75); 
    color:rgba(26,176,136,255) ;
 }
.register-form>*{
    margin-top: 2em;
}